<template>
  <b-card no-body>
    <b-card-body>

      <b-row>
        <b-col cols="12" md="4" sm="6" class="mb-md-0 mb-2">
          <label>Type</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="filterByType"
            :options="typeOption"
            class="w-100"
            label="label"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:filterByType', val)"
            placeholder="Select Type.."
          />
        </b-col>
        <b-col cols="12" md="4" sm="6" class="mb-md-0 mb-2">
          <label>Shift</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="filterByShift"
            :options="shiftOption"
            class="w-100"
            label="label"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:filterByShift', val)"
            placeholder="Select Shift.."
          />
        </b-col>
        <b-col cols="12" md="4" sm="6" class="mb-md-0 mb-2">
          <label>Year</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="filterByYear"
            :options="YearOption"
            class="w-100"
            label="label"
            @input="(val) => $emit('update:filterByYear', val)"
            placeholder="Select Year.."
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardBody, BRow, BCol } from "bootstrap-vue";
import vSelect from "vue-select";

// Set the components prop default to return our fresh components
vSelect.props.components.default = () => ({
  Deselect: {
    render: (h) => h("feather-icon", { props: { size: "14", icon: "XIcon" } }),
  },
  OpenIndicator: {
    render: (h) =>
      h("feather-icon", {
        props: { size: "15", icon: "ChevronDownIcon" },
        class: "open-indicator",
      }),
  },
});

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    vSelect,
  },
  props: {
    filterByType: {},
    typeOption: {
      type: Array,
      required: true,
    },
    filterByShift: {},
    shiftOption: {
      type: Array,
      required: true,
    },
    filterByYear: {},
  },
  data(){
    return{
      YearOption:[
        new Date().getFullYear() - 2,
        new Date().getFullYear() - 1,
        new Date().getFullYear(),
        new Date().getFullYear() +1,
      ],
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
